/** @jsx jsx */
import {jsx} from 'theme-ui'
import {InputHTMLAttributes} from 'react'

interface IInput extends InputHTMLAttributes<HTMLInputElement> {}

const Input: React.FC<IInput> = (props) => {
  return (
    <input
      sx={{
        padding: '10px 15px',
        width: '100%',
        height: '100%',
        borderRadius: 4,
        fontSize: 13,
        border: '1px solid #DEE3E3',
        outline: 0,
        '&:focus': {
          border: '1px solid #055064',
          '::placeholder': {
            color: '#055064',
          },
        },
        '::placeholder': {
          color: '#717A7B',
        },
      }}
      {...props}
    />
  )
}

export default Input
