/** @jsx jsx */
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import {toast} from 'react-toastify'
import {graphql, PageProps} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import Input from 'components/Input'
import {ContactQuery} from '../graphqlTypes'
import {useState, ChangeEvent, FormEvent} from 'react'
import Button from 'components/Button'
import {PageContext} from 'gatsbyTypes'
import {useTranslation} from 'react-i18next'
import useAutoSelectLanguage from 'hooks/useAutoSelectLanguage'

interface ContactPageProps extends PageProps<ContactQuery, PageContext> {}

const ContactPage: React.FC<ContactPageProps> = ({data, pageContext}) => {
  useAutoSelectLanguage(pageContext)
  const {t} = useTranslation()
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    text: '',
  })
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (!event.target) {
      return
    }
    const {name, value} = event.target
    setUserInfo({
      ...userInfo,
      [name]: value,
    })
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const {name, text, email} = userInfo
    toast.warn('The contact form is not implemented yet')
    // TODO: send form information to API
  }
  return (
    <Layout>
      <SEO
        title={t('contactUsSEOTitle')}
        description={t('contactUsSEODescription')}
      />
      <BackgroundImage
        Tag="header"
        sx={{
          width: '100%',
          height: 756,
          display: 'flex',
          backgroundPosition: 'left',
        }}
        fluid={data.heroImage.childImageSharp.fluid}
      >
        <div
          sx={{
            width: [, , 1440 - 206 * 2],
            mx: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <form
            onSubmit={handleSubmit}
            sx={{
              p: 36,
              bg: 'white',
              borderRadius: 16,
              width: [, , 432],
            }}
          >
            <h1
              sx={{
                fontSize: 24,
                fontWeight: 400,
                my: 0,
              }}
            >
              {t('contactUsTitle')}
            </h1>
            <p
              sx={{
                mt: 12,
                mb: 28,
                fontSize: 14,
                color: 'soft',
              }}
            >
              {t('contactUsSubtitle')}
            </p>

            <label sx={{mt: 24, display: 'block'}}>
              <span
                sx={{
                  display: 'block',
                  fontSize: 14,
                  fontWeight: 300,
                  mb: 6,
                }}
              >
                {t('Your name')}
              </span>
              <Input
                name="name"
                autoComplete="name"
                type="text"
                required
                onChange={handleChange}
              />
            </label>
            <label sx={{mt: 24, display: 'block'}}>
              <span
                sx={{
                  display: 'block',
                  fontSize: 14,
                  fontWeight: 300,
                  mb: 6,
                }}
              >
                {t('Your email')}
              </span>
              <Input
                name="email"
                autoComplete="email"
                type="email"
                required
                onChange={handleChange}
              />
            </label>
            <label sx={{mt: 24, display: 'block'}}>
              <span
                sx={{
                  display: 'block',
                  fontSize: 14,
                  fontWeight: 300,
                  mb: 6,
                }}
              >
                {t('Subject')}
              </span>
              <textarea
                name="text"
                required
                onChange={handleChange}
                rows={10}
                sx={{
                  padding: '10px 15px',
                  width: '100%',
                  height: '100%',
                  borderRadius: 4,
                  fontSize: 13,
                  border: '1px solid #DEE3E3',
                  outline: 0,
                  '&:focus': {
                    border: '1px solid #055064',
                  },
                }}
              />
            </label>
            <Button
              type="submit"
              sx={{
                mt: 24,
              }}
            >
              {t('Send')}
            </Button>
          </form>
        </div>
      </BackgroundImage>
    </Layout>
  )
}
export default ContactPage

export const pageQuery = graphql`
  query Contact {
    heroImage: file(relativePath: {eq: "contact-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
